<template>
  <span>
    <span class="float-right">
      <button
        type="button"
        class="btn btn-success btn-xs mx-1"
        @click="toggle = !toggle">
        บันทึก
      </button>
    </span>

    <b-modal
      v-model="toggle"
      :title="item.name"
      v-if="item">
      <div v-if="item.description" class="text-primary">
        {{item.description}}
      </div>

      <div v-if="item.cron">
        <span class="">
          เวลา:
        </span>
        {{item.cron}}
      </div>

      <div v-if="item.inventory">
        <span class="">
          เครื่องมือ:
        </span>
        {{item.inventory.code}} ({{item.inventory.name}})
      </div>

      <template v-if="item.formType === 'between'">
        <div class="pb-2">
          <span class="text-danger">{{item.minValue | comma(-1)}}</span>
          <fa icon="less-than-equal" class="mx-1"></fa>
          {{item.name}}
          <fa icon="less-than-equal" class="mx-1"></fa>
          <span class="text-success">{{item.maxValue | comma(-1)}}</span>
        </div>

        <div class="form-row" v-if="$auth.hasRole(`doc:${docType}:${tag}:add`)">
          <sgv-input-number
            class="col-12"
            minus
            :precision="2"
            v-model="formData.actualValue">
          </sgv-input-number>

          <sgv-input-text
            placeholder="หมายเหตุ (ถ้ามี)"
            v-model="formData.remark"
            class="col-12">
          </sgv-input-text>
        </div>

        <div class="measurement-list">
          <ul class="my-0">
            <li
              v-for="child in item.children"
              :key="child.id"
              class="my-1">
              <span class="text-primary">
                {{child.createdAt | datetime}}:
              </span>
              <span>{{child.actualValue | comma}}</span>
              <small>
                <div>
                  {{child.createdUser.name}}
                  <div
                    class="text-danger"
                    v-if="child.remark">
                    {{child.remark}}
                  </div>
                </div>
              </small>
            </li>
          </ul>
        </div>
      </template>

      <template v-if="item.formType === 'choice'">
        <div class="form-row" v-if="$auth.hasRole(`doc:${docType}:${tag}:add`)">
          <sgv-input-radio
            class="col-12 mb-2"
            :options="item.choices"
            select="value"
            inline
            v-model="formData.actualValue">
            <div slot="label">
              {{item.name}}
            </div>
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-radio>

          <sgv-input-text
            placeholder="หมายเหตุ (ถ้ามี)"
            v-model="formData.remark"
            class="col-12">
          </sgv-input-text>
        </div>

        <div class="measurement-list">
          <ul class="my-0">
            <li
              v-for="child in item.children"
              :key="child.id"
              class="my-1">
              <span class="text-primary">
                {{child.createdAt | datetime}}:
              </span>
              <span>{{getChoice(item.choices, child.actualValue)}}</span>
              <small>
                <div>
                  {{child.createdUser.name}}
                  <div
                    class="text-danger"
                    v-if="child.remark">
                    {{child.remark}}
                  </div>
                </div>
              </small>
            </li>
          </ul>
        </div>
      </template>

      <div slot="modal-footer" class="col-12 px-0">
        <button
          v-if="$auth.hasRole(`doc:${docType}:${tag}:add`)"
          type="button"
          @click="addData"
          class="btn btn-block btn-success">
          บันทึก
        </button>
      </div>
    </b-modal>
  </span>
</template>

<script>
import {
  DETAIL_MEASUREMENT,
  CREATE_MEASUREMENT
} from './graph'

export default {
  props: {
    tag: {
      type: String,
      required: true
    },
    measurementId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        actualValue: 0,
        remark: '',
      },
      item: null
    }
  },
  methods: {
    fetchData () {
      this.$apollo.query({
        query: DETAIL_MEASUREMENT(this.templateType, this.tag),
        variables: {
          docType: this.docType,
          docId: this.docId,
          measurementId: this.measurementId
        },
        fetchPolicy: 'no-cache'
      }).then(res => {
        this.item = res.data.item
        this.formData.actualValue = res.data.item.targetValue
        this.formData.remark = ''
      })
    },
    getChoice (choices, value) {
      return choices.find(v => v.value === value)?.text || ''
    },
    serializeInput (item) {
      return {
        actualValue: item.actualValue,
        remark: item.remark,
      }
    },
    addData () {
      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_MEASUREMENT(this.templateType, this.tag),
        variables: {
          docType: this.docType,
          docId: this.docId,
          measurementId: this.measurementId,
          input
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.toggle = false
        this.$toasted.global.success('เพิ่มสำเร็จ')
      })
      .catch(this.$toasted.global.error)
    }
  },
  watch: {
    toggle (value) {
      if (value && this.docId > 0) this.fetchData()
    }
  }
}
</script>

<style lang="css" scoped>
.measurement-list {
  overflow-y: auto;
  max-height: 50vh;
}
</style>
