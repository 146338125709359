<template>
  <div class="">
    <div class="mb-3" v-if="$auth.hasRole(`doc:${docType}:${tag}:setup`)">
      <button
        class="btn btn-outline-warning"
        @click="setupProcedure">
        ตั้งค่าเครื่องจักร
      </button>
    </div>

    <div
      class="mb-4"
      v-for="(section, i) in items"
      :key="section.code">

      <ul class="list-group">
        <li class="list-group-item">{{i+1}}. {{section.code}} ({{section.name}})</li>
        <li
          class="list-group-item"
          v-for="child in section.children"
          :key="child.code">

          <div class="">
            {{child.code}} ({{child.name}}):
            <span v-if="child.formType === 'between'">
              <span v-if="child.minValue > 0">
                <span class="text-danger mr-1">{{child.minValue | comma(-1)}}</span>
                <fa icon="less-than-equal" class="text-secondary"></fa>
              </span>
              <span class="mx-1">{{child.targetValue | comma(-1)}}</span>
              <span v-if="child.maxValue > 0">
                <fa icon="less-than-equal" class="text-secondary"></fa>
                <span class="text-success mx-1">{{child.maxValue | comma(-1)}}</span>
              </span>
            </span>

            <DetailMeasurementForm
              :measurementId="child.id"
              :tag="tag"
              :docId="docId"
              :docType="docType"
              :templateType="templateType"
              @updated="$apollo.queries.items.refetch()">
            </DetailMeasurementForm>

            <DetailMeasurementDevice
              v-if="child.deviceId"
              :measurementId="child.id"
              :item="child"
              :tag="tag"
              :docId="docId"
              :docType="docType"
              :templateType="templateType"
              :formData="formData">
            </DetailMeasurementDevice>
          </div>

          <div v-if="child.description" class="text-primary pre-line">
            <small>{{child.description}}</small>
          </div>

          <div v-if="child.cron">
            <span class="text-info">
              เวลา:
            </span>
            {{child.cron}}
          </div>

          <div v-if="child.inventory">
            <span class="text-warning">
              เครื่องมือ:
            </span>
            {{child.inventory.code}} ({{child.inventory.name}})
          </div>

          <div v-if="child.device">
            <span class="text-success">
              อุปกรณ์:
            </span>
            {{child.device.code}} ({{child.device.name}})
          </div>

          <div v-if="child.children">
            <ul>
              <li
                v-for="result in child.children"
                :key="result.id">
                <span v-if="child.formType === 'choice'">
                  {{getChoice(child.choices, result.actualValue)}}
                </span>
                <span v-else>
                  {{result.actualValue}}
                </span>
                <span class="text-primary">
                  {{result.createdAt | datetime}}
                  {{result.createdUser.name}}
                </span>
                <div class="text-danger" v-if="result.remark">
                  <small>{{result.remark}}</small>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DetailMeasurementForm from './DetailMeasurementForm'
import DetailMeasurementDevice from './DetailMeasurementDevice'
import {
  LIST_MEASUREMENT,
  SETUP_PROCEDURE_CONTROL,
  SETUP_PROCEDURE_QUATITY,
} from './graph'

export default {
  props: {
    tag: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}Detail${this.$form.capitalize(this.tag)}`,
      items: []
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_MEASUREMENT(this.templateType, this.tag)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      fetchPolicy: 'no-cache',
      skip () {
        return this.docId === 0
      }
    }
  },
  methods: {
    getChoice (choices, value) {
      return choices.find(v => v.value === value)?.text || ''
    },
    setupProcedure () {
      const mutation = this.tag === 'control' ? SETUP_PROCEDURE_CONTROL(this.templateType) : SETUP_PROCEDURE_QUATITY(this.templateType)
      this.$apollo.mutate({
        mutation,
        variables: {
          docType: this.docType,
          docId: this.docId
        }
      })
      .then(() => {
        this.$toasted.global.success('ส่งข้อมูลไปยังเครื่องจักร')
      }).catch((err) => {
        this.$toasted.global.error(err)
      })
    }
  },
  components: {
    DetailMeasurementForm,
    DetailMeasurementDevice
  }
}
</script>

<style lang="css" scoped>
</style>
