<template>
  <fa
    class="pointer"
    icon="print"
    :class="{'text-warning': isPrinted}"
    @click="printTag">
  </fa>
</template>

<script>
import { PRINT_LABEL_INVENTORY_STOCK } from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    deviceId: {
      type: Number,
    },
    stockId: {
      type: Number,
      required: true
    },
    isPrinted: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      qty: 1
    }
  },
  methods: {
    printTag () {
      if (!this.deviceId) {
        this.$toasted.global.error('โปรดเลือกเครื่องพิมพ์')
        return
      }

      this.$apollo.mutate({
        mutation: PRINT_LABEL_INVENTORY_STOCK(this.templateType),
        variables: {
          docType: this.docType,
          deviceId: this.deviceId,
          stockId: this.stockId,
          qty: this.qty
        }
      })
      .then(() => {
        this.$toasted.global.success('ส่งข้อมูลไปยังเครื่องพิมพ์...')
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
