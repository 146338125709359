<template>
  <div>
    <sgv-input-select
      label="ป้ายบริษัท"
      placeholder="เลือกเครื่องพิมพ์"
      :options="devices"
      v-model="selected"
      select="id"
      class="mb-2">
      <template slot-scope="option">
        {{option.name}}
      </template>
    </sgv-input-select>

    <div class="input-group">
      <input
        type="number"
        class="form-control"
        placeholder="จำนวน"
        v-model.number="qty"
        @keyup.enter="printData">
      <div class="input-group-append">
        <span @click="printData" class="input-group-text pointer">
          <fa icon="print" class="text-warning"></fa>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  LIST_LABEL_CONTACT_COMPANY_DEVICE,
  PRINT_LABEL_CONTACT_COMPANY
} from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      qty: '',
      devices: [],
      selected: null
    }
  },
  apollo: {
    devices: {
      query () {
        return LIST_LABEL_CONTACT_COMPANY_DEVICE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType
        }
      },
      result (res) {
        const devices = res.data.devices

        if (devices.length === 1) {
          this.selected = devices[0].id
        }
      }
    }
  },
  methods: {
    printData () {
      if (this.qty > 0 && this.selected) {
        this.$apollo.mutate({
          mutation: PRINT_LABEL_CONTACT_COMPANY(this.templateType),
          variables: {
            docType: this.docType,
            deviceId: this.selected,
            qty: this.qty
          }
        })
        .then(() => {
          this.qty = ''
          this.$toasted.global.success('ส่งข้อมูลไปยังเครื่องพิมพ์...')
        })
        .catch(err => {
          this.$toasted.global.error(err)
        })
      } else {
        this.$toasted.global.error("ระบุข้อมูลไม่ถูกต้อง")
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
