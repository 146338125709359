<template>
  <div>
    <span
      type="button"
      @click="toggle = true"
      class="text-warning pointer">
      ตรวจรับสินค้า
    </span>

    <b-modal v-model="toggle" title="ตรวจรับสินค้า">
      <div class="row">
        <DetailOrderCheckoutQrcode
          :docType="docType"
          :templateType="templateType"
          v-if="selected === 'qrcode' && toggle"
          class="col-12"
          @barcode="onBarcode">
        </DetailOrderCheckoutQrcode>

        <DetailOrderCheckoutScanner
          :docType="docType"
          :templateType="templateType"
          v-else-if="selected === 'scanner' && toggle"
          class="col-12"
          @barcode="onBarcode"
          @error="selected = null">
        </DetailOrderCheckoutScanner>

        <div class="col-12" v-else>
          <button
            type="button"
            class="btn btn-primary btn-block btn-lg"
            @click="selected = 'scanner'">
            <fa icon="barcode"></fa> แสกนด้วยอุปกรณ์
          </button>

          <div class="my-3 text-center col-12">
            หรือ
          </div>

          <button
            type="button"
            class="btn btn-warning btn-block btn-lg"
            @click="selected = 'qrcode'">
            <fa icon="camera"></fa> แสกนด้วยกล้อง
          </button>
        </div>
      </div>

      <template v-slot:modal-footer="{cancel}">
        <button
          type="button"
          class="btn btn-danger"
          @click="cancel()">
          กลับ
        </button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import DetailOrderCheckoutQrcode from './DetailOrderCheckoutQrcode'
import DetailOrderCheckoutScanner from './DetailOrderCheckoutScanner'
import {
  APPROVE_PRODUCT_ORDER_BY_STOCK_ID
} from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      toggle: false,
      selected: null
    }
  },
  methods: {
    onBarcode (value) {
      if (!value) return

      let stockId
      try {
        const arr = value.split(',')
        const type = arr[0]
        if (type === 'stockId') {
          stockId = arr[1]
        } else {
          stockId = value
        }

        if (this.$form.isNumber(stockId)) {
          this.debouncedCheckoutStock(+stockId)
        } else {
          this.$toasted.global.error('barcode ไม่ถูกต้อง')
        }
      } catch (err) {
        this.$toasted.global.error('barcode ไม่ถูกต้อง')
      }
    },
    checkoutStock (stockId) {
      this.$apollo.mutate({
        mutation: APPROVE_PRODUCT_ORDER_BY_STOCK_ID(this.templateType),
        variables: {
          docType: this.docType,
          stockId
        }
      })
      .then(() => {this.$toasted.global.success("ตรวจรับสำเร็จ")})
      .catch(this.$toasted.global.error)
    }
  },
  watch: {
    toggle (v) {
      if (v === false) {
        this.selected = null
      }
    }
  },
  created () {
    this.debouncedCheckoutStock = debounce(this.checkoutStock, 150)
  },
  components: {
    DetailOrderCheckoutQrcode,
    DetailOrderCheckoutScanner
  }
}
</script>
