<template>
  <span>
    <span class="float-right">
      <button
        type="button"
        class="btn btn-info btn-xs mx-1"
        @click="toggle = !toggle">
        อุปกรณ์
      </button>
    </span>

    <b-modal
      hide-footer
      v-model="toggle"
      :title="item.name">
      <div v-if="item.description" class="text-primary">
        {{item.description}}
      </div>

      <div>
        <span class="">
          อุปกรณ์:
        </span>
        {{item.device.code}} ({{item.device.name}})
      </div>

      <template v-if="item.formType === 'between'">
        <div class="pb-2">
          <span class="text-danger">{{item.minValue | comma(-1)}}</span>
          <fa icon="less-than-equal" class="mx-1"></fa>
          {{item.name}}
          <fa icon="less-than-equal" class="mx-1"></fa>
          <span class="text-success">{{item.maxValue | comma(-1)}}</span>
        </div>
      </template>

      <template v-if="item.formType === 'choice'">
        <div class="form-row">
          <sgv-input-radio
            class="col-12 mb-2"
            :options="item.choices"
            select="value"
            inline
            v-model="formData.actualValue">
            <div slot="label">
              {{item.name}}
            </div>
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-radio>
        </div>
      </template>

      <div class="form-row">
        <sgv-input-select
          class="col-6"
          label="ช่วงข้อมูล"
          :options="buckets"
          v-model="selectedBucket"
          select="value">
          <template slot-scope="option">
            {{option.text}}
          </template>
        </sgv-input-select>

        <sgv-input-select
          class="col-6"
          label="ข้อมูลย้อนหลัง"
          :options="times"
          v-model="selectedTime"
          select="value">
          <template slot-scope="option">
            {{option.text}}
          </template>
        </sgv-input-select>
      </div>

      <div class="form-row">
        <sgv-input-datetime
          :disabled="selectedTime !== 'custom'"
          class="col-6"
          v-model="startDate"
          label="เริ่ม"
          isNull
          :validations="[
            {text: 'required!', value: $v.startDate.$dirty && $v.startDate.$error}
          ]">
        </sgv-input-datetime>

        <sgv-input-datetime
          :disabled="selectedTime !== 'custom'"
          class="col-6"
          v-model="endDate"
          label="ถึง"
          isNull
          :validations="[
            {text: 'required!', value: $v.endDate.$dirty && $v.endDate.$error}
          ]">
          <label slot="label" class="ml-2" v-if="selectedTime === 'custom'">
            <span @click="setNow" class="text-primary pointer">ตอนนี้</span>
          </label>
        </sgv-input-datetime>
      </div>

      <button
        type="button"
        class="btn btn-warning"
        @click="fetchData">
        ค้นหา
      </button>

      <DetailMeasurementDeviceXlsx
        v-if="children.length > 0"
        class="ml-2"
        :measurement="item"
        :children="children"
        :formData="formData">
      </DetailMeasurementDeviceXlsx>

      <div class="measurement-list mt-3">
        <ul>
          <li
            v-for="child in children"
            :key="child.time">
            <span class="text-primary">{{child.time | datetime}}: </span>
            <span :class="isInRange(child.actualValue, item.minValue, item.maxValue)">
              {{child.actualValue | comma}}
            </span>
          </li>
        </ul>
      </div>
    </b-modal>
  </span>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DetailMeasurementDeviceXlsx from './DetailMeasurementDeviceXlsx.vue'
import { LIST_MEASUREMENT_DEVICE } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    tag: {
      type: String,
      required: true
    },
    measurementId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}DetailMeasurementDevice`,
      toggle: false,
      children: [],
      buckets: [
        {text: '1 นาที', value: '1 minute'},
        {text: '5 นาที', value: '5 minutes'},
        {text: '15 นาที', value: '15 minutes'},
        {text: '30 นาที', value: '30 minutes'},
        {text: '1 ชั่วโมง', value: '1 hour'},
        {text: '3 ชั่วโมง', value: '3 hours'},
        {text: '6 ชั่วโมง', value: '6 hours'},
        {text: '12 ชั่วโมง', value: '12 hours'},
        {text: '24 ชั่วโมง', value: '24 hours'},
      ],
      times: [
        {text: '3 ชั่วโมง', value: '3 hours'},
        {text: '12 ชั่วโมง', value: '12 hours'},
        {text: '24 ชั่วโมง', value: '24 hours'},
        {text: 'ทั้งหมด', value: 'all'},
        {text: 'กำหนดเอง', value: 'custom'},
      ],
      selectedTime: '3 hours',
      rFields: ['selectedBucket', 'selectedTime', 'startDate', 'endDate'],
      selectedBucket: '5 minutes',
      startDate: null,
      endDate: null,
    }
  },
  validations: {
    startDate: { required },
    endDate: { required },
  },
  methods: {
    fetchData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.children = []

      this.$apollo.query({
        query: LIST_MEASUREMENT_DEVICE(this.templateType, this.tag),
        variables: {
          docType: this.docType,
          docId: this.docId,
          measurementId: this.measurementId,
          bucket: this.selectedBucket,
          startDate: this.$date.format(this.startDate).raw,
          endDate: this.$date.format(this.endDate).raw,
        },
        fetchPolicy: 'no-cache'
      }).then(res => {
        this.children = res.data.children
      })
    },
    getChoice (choices, value) {
      return choices.find(v => v.value === value).text
    },
    isInRange(value, minValue, maxValue) {
      if (value < minValue || value > maxValue) return ['text-danger']
      return []
    },
    setNow () {
      this.endDate = this.$date.format(new Date()).datetime
    },
    setDefaultTime () {
      const v = this.selectedTime
      const now = new Date()
      if (v === '3 hours') {
        this.startDate = this.$date.format(now, -3600*3).datetime
        this.endDate = this.$date.format(now).datetime
      } else if (v === '12 hours') {
        this.startDate = this.$date.format(now, -3600*12).datetime
        this.endDate = this.$date.format(now).datetime
      } else if (v === '24 hours') {
        this.startDate = this.$date.format(now, -3600*24).datetime
        this.endDate = this.$date.format(now).datetime
      } else if (v === 'all') {
        this.startDate = this.formData.approvedAt ? this.$date.format(this.formData.approvedAt).datetime : this.$date.format(now).datetime
        this.endDate = this.formData.closedAt ? this.$date.format(this.formData.closedAt).datetime : this.$date.format(now).datetime
      }
    }
  },
  watch: {
    toggle (v) {
      if (v) this.setDefaultTime()
    },
    selectedTime: 'setDefaultTime',
    selectedBucket: 'setRetaining',
    startDate: 'setRetaining',
    endDate: 'setRetaining'
  },
  components: {
    DetailMeasurementDeviceXlsx
  }
}
</script>

<style lang="css" scoped>
.measurement-list {
  overflow-y: auto;
  max-height: 50vh;
}
</style>
