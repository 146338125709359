import gql from 'graphql-tag'


export const LIST_REPAIR = (templateType) => gql`query LIST_REPAIR ($docType: String!, $docId: Int!, $q: FilterInput) {
  docs: listDoc${templateType}Repair (docType: $docType, docId: $docId, q: $q) {
    id type code name remark
  }
}`

export const CREATE_REPAIR = (templateType) => gql`mutation CREATE_REPAIR ($docType: String!, $docId: Int!, $repairId: Int!) {
  createDoc: createDoc${templateType}Repair (docType: $docType, docId: $docId, repairId: $repairId) {
    id parentId childId
  }
}`

export const DESTROY_REPAIR = (templateType) => gql`mutation DESTROY_REPAIR ($docType: String!, $docId: Int!, $repairId: Int!) {
  destroyDoc: destroyDoc${templateType}Repair (docType: $docType, docId: $docId, repairId: $repairId) {
    id parentId childId
  }
}`
