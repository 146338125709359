<template>
  <button
    @click="download"
    class="btn btn-success">
    <fa icon="download"></fa>
    Download
  </button>
</template>

<script>
import xlsx from 'json-as-xlsx'

export default {
  props: {
    children: {
      type: Array,
      required: true
    },
    measurement: {
      type: Object,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      sheetname: '000000',
      columns: [
        {label: 'เวลา', value: 'time'},
        {label: 'ค่าจริง', value: 'actualValue'},
        {label: 'เป้าหมาย', value: 'targetValue'},
        {label: 'สูงสุด', value: 'maxValue'},
        {label: 'ต่ำสุด', value: 'minValue'},
      ]
    }
  },
  computed: {
    filename () {
      return `${this.formData.code}-${this.measurement.code}`
    },
    rows () {
      const content = this.children.flatMap(v => {
        return {
          time : this.$date.format(v.time).displayDatetime,
          actualValue : v.actualValue,
          targetValue : this.measurement.targetValue,
          maxValue : this.measurement.maxValue,
          minValue : this.measurement.minValue,
        }
      })

      const data = [{
        sheet: this.sheetname,
        columns: this.columns,
        content
      }]

      return data
    }
  },
  methods: {
    download () {
      const settings = {
        fileName: this.filename,
      }

      xlsx(this.rows, settings)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
