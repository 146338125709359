<template>
  <div class="">
    <div class="row" v-if="isEmployee">
      <DetailOrderEmployee
        label="พนักงานผลิต"
        class="col-12 col-sm-6"
        :docType="docType"
        :templateType="templateType"
        select="id"
        v-model="employeeId">
      </DetailOrderEmployee>
    </div>

    <DetailOrderProduct
      :employeeId="employeeId"
      :docType="docType"
      :templateType="templateType"
      class="mb-4"
      :items="itemsByType('product')">
    </DetailOrderProduct>

    <DetailOrderMaterial
      :employeeId="employeeId"
      :docType="docType"
      :templateType="templateType"
      class="mb-4"
      :items="itemsByType('material')">
    </DetailOrderMaterial>

    <DetailOrderScrap
      :employeeId="employeeId"
      :docType="docType"
      :templateType="templateType"
      class="mb-4"
      :items="itemsByType('scrap')">
    </DetailOrderScrap>
  </div>
</template>

<script>
import DetailOrderProduct from './DetailOrderProduct'
import DetailOrderMaterial from './DetailOrderMaterial'
import DetailOrderScrap from './DetailOrderScrap'
import DetailOrderEmployee from './DetailOrderEmployee'
import {
  LIST_ORDER,
  WATCH_DOC_CLOSED,
  WATCH_DOC_UPDATED,
  WATCH_ORDER_CREATED,
  WATCH_ORDER_DESTROYED,
  WATCH_ORDER_UPDATED
} from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    isEmployee: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      employeeId: null,
      orders: []
    }
  },
  apollo: {
    orders: {
      query () {
        return LIST_ORDER(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      skip () {
        return this.docId <= 0
      },
      subscribeToMore: [
        {
          document () {
            return WATCH_ORDER_CREATED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const newOrder = subscriptionData.data.orderCreated

            if (!newOrder.parentId) return

            const orders = [...previous.orders]
            const found = orders.find(v => v.id === newOrder.parentId)

            if (!found) return

            found.children.unshift(newOrder)
            return {...previous, orders}
          }
        },
        {
          document () {
            return WATCH_ORDER_UPDATED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            let orderIndex = -1
            let childIndex = -1
            previous.orders.forEach((order, i) => {
              order.children.forEach((child, z) => {
                if (child.id === subscriptionData.data.orderUpdated.id) {
                  orderIndex = i
                  childIndex = z
                }
              })
            })

            if (orderIndex === -1 || childIndex === -1) return

            const orders = [...previous.orders]
            orders[orderIndex].children[childIndex] = subscriptionData.data.orderUpdated
            return {...previous, orders}
          }
        },
        {
          document () {
            return WATCH_ORDER_DESTROYED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            let orderIndex = -1
            let childIndex = -1
            previous.orders.forEach((order, i) => {
              order.children.forEach((child, z) => {
                if (child.id === subscriptionData.data.orderDestroyed.id) {
                  orderIndex = i
                  childIndex = z
                }
              })
            })

            if (orderIndex === -1 || childIndex === -1) return

            const orders = [...previous.orders]
            orders[orderIndex].children.splice(childIndex, 1)
            return {...previous, orders}
          }
        }
      ]
    },
    $subscribe: {
      docClosed: {
        query () {
          return WATCH_DOC_CLOSED(this.templateType)
        },
        variables () {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result () {
          this.$apollo.queries.orders.refetch()
        }
      },
      docUpdated: {
        query () {
          return WATCH_DOC_UPDATED(this.templateType)
        },
        variables () {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result () {
          this.$apollo.queries.orders.refetch()
        }
      },
    },
  },
  methods: {
    itemsByType (type) {
      return this.orders.filter(v => v.type === type)
    }
  },
  components: {
    DetailOrderProduct,
    DetailOrderMaterial,
    DetailOrderScrap,
    DetailOrderEmployee
  }
}
</script>

<style lang="css">
</style>
