<template>
  <div>
    <div
      class="col-6 col-md-4 mb-3"
      style="max-height:250px;"
      v-for="item in images"
      :key="item.id">
      <img
        v-if="item.src"
        :src="item.src"
        class="img-fluid rounded">
    </div>
  </div>
</template>

<script>
import {
  LIST_ATTACHMENT_INVENTORY,
  DETAIL_ATTACHMENT_INVENTORY,
} from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    inventoryId: {
      type: Number,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: [],
      images: []
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_ATTACHMENT_INVENTORY(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          inventoryId: this.inventoryId
        }
      },
      fetchPolicy: 'no-cache',
      result (res) {
        res.data.items.forEach(async v => {
          this.fetchCover(v)
          this.images.push({
            id: v.id,
            filename: v.filename,
            src: ''
          })
        })
      }
    }
  },
  methods: {
    fetchCover (item) {
      let extension = item.filename.split('.').pop()
      if (['jpg', 'jpeg', 'png', 'svg'].includes(extension.toLowerCase())) {
        return this.$apollo.query({
          query: DETAIL_ATTACHMENT_INVENTORY(this.templateType),
          variables: {
            docType: this.docType,
            attachmentId: item.id
          },
          fetchPolicy: 'network-only'
        })
        .then(res => {
          const index = this.images.findIndex(v => v.id === item.id)
          if (index !== -1) {
            this.images[index].src = res.data.url
          }
        })
        .catch(() => '')
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
