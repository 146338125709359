<template>
  <div class="row">
    <DetailLabelInventoryBarcodeDetail
      class="col-12 mb-4"
      :inventories="inventories.filter(v => v.type === 'product')"
      :docType="docType"
      :templateType="templateType">
    </DetailLabelInventoryBarcodeDetail>

    <DetailLabelContactCompany
      class="col-12 col-sm-6 mb-4"
      :docType="docType"
      :templateType="templateType">
    </DetailLabelContactCompany>

    <DetailLabelInventoryCaution
      class="col-12 col-sm-6 mb-4"
      :docType="docType"
      :templateType="templateType">
    </DetailLabelInventoryCaution>
  </div>
</template>

<script>
import DetailLabelContactCompany from './DetailLabelContactCompany'
import DetailLabelInventoryCaution from './DetailLabelInventoryCaution'
import DetailLabelInventoryBarcodeDetail from './DetailLabelInventoryBarcodeDetail'
import { LIST_ORDER_INVENTORY } from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      inventories: []
    }
  },
  apollo: {
    inventories: {
      query () {
        return LIST_ORDER_INVENTORY(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      }
    }
  },
  components: {
    DetailLabelContactCompany,
    DetailLabelInventoryCaution,
    DetailLabelInventoryBarcodeDetail,
  }
}
</script>

<style lang="css">
</style>
