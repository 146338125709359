<template>
  <sgv-input-select
    placeholder="เลือกเครื่องพิมพ์"
    :options="devices"
    :value="value"
    @input="emitInput"
    select="id">
    <template slot-scope="option">
      {{option.name}}
    </template>
  </sgv-input-select>
</template>

<script>
import {
  LIST_LABEL_INVENTORY_STOCK_DEVICE
} from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    value: {
      type: Number
    }
  },
  data () {
    return {
      devices: [],
    }
  },
  apollo: {
    devices: {
      query () {
        return LIST_LABEL_INVENTORY_STOCK_DEVICE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType
        }
      },
      result (res) {
        const devices = res.data.devices

        if (devices.length === 1) {
          this.emitInput(devices[0].id)
        }
      }
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
