import { render, staticRenderFns } from "./DetailFormProcedureInput.vue?vue&type=template&id=152c5b14&scoped=true&"
import script from "./DetailFormProcedureInput.vue?vue&type=script&lang=js&"
export * from "./DetailFormProcedureInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "152c5b14",
  null
  
)

export default component.exports