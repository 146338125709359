<template>
  <div class="">
    <sgv-input-select
      :options="devices"
      v-model="selectedId"
      placeholder="เลือกบาร์โค๊ดสแกนเนอร์"
      select="id">
      <template slot-scope="option">
        {{option.code}} ({{option.name}})
      </template>
    </sgv-input-select>

    Barcode: {{barcode}}
  </div>
</template>

<script>
import {
  LIST_BARCODE_SCANNER,
  SETUP_BARCODE_SCANNER,
  WATCH_BARCODE_SCANNER_SCANNED
} from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      barcode: '...',
      uid: '',
      timeout: null,
      devices: [],
      selectedId: null
    }
  },
  apollo: {
    devices: {
      query () {
        return LIST_BARCODE_SCANNER(this.templateType)
      },
      variables () {
        return {
          docType: this.docType
        }
      },
      result (res) {
        const devices = res.data.devices
        if (devices.length === 1) {
          this.selectedId = devices[0].id
        }
      },
      fetchPolicy: 'no-cache'
    },
    $subscribe: {
      scanned: {
        query () {
          return WATCH_BARCODE_SCANNER_SCANNED(this.templateType)
        },
        variables () {
          return {
            docType: this.docType,
            uid: this.uid
          }
        },
        result ({data}) {
          const barcode = data.scanned.barcode
          this.barcode = barcode
          this.$emit('barcode', barcode)

          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.barcode = ''
          }, 5000)
        },
        skip () {
          return !this.uid
        }
      }
    }
  },
  methods: {
    onBarcode (value) {
      this.$emit('barcode', value)
    },
    setUid (deviceId) {
      if (deviceId) {
        this.barcode = 'กำลังตั้งค่าอุปกรณ์...'
        this.$apollo.mutate({
          mutation: SETUP_BARCODE_SCANNER(this.templateType),
          variables: {
            docType: this.docType,
            deviceId
          }
        })
        .then(res => {
          this.uid = res.data.uid
          this.barcode = 'พร้อมใช้งาน...'
        })
        .catch(() => {
          this.$toasted.global.error('ไม่สามารถตั้งค่าเครื่องแสกนได้')
          this.$emit('error', null)
        })
      }
    }
  },
  watch: {
    selectedId (deviceId) {
      this.setUid(deviceId)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
