<template>
  <qrcode-stream @decode="onBarcode" camera="rear"></qrcode-stream>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  methods: {
    onBarcode (value) {
      this.$emit('barcode', value)
    }
  },
  components: {
    QrcodeStream
  }
}
</script>

<style lang="css" scoped>
</style>
