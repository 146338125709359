<template>
  <ul class="list-group">
    <li class="list-group-item text-warning">วัตถุดิบ</li>

    <li v-for="item in items" :key="item.id" class="list-group-item">
      <div class="row">
        <div @click="toggle(item)" class="pointer col-12">
          {{item.name}}
          <span class="float-right">
            <span v-if="item.minQty > 0">
              <span class="text-warning mr-1">{{item.minQty | comma(-1)}}</span>
              <fa icon="chevron-left" class="text-secondary"></fa>
            </span>
            <span class="mx-1">{{totalQty(item.children) | comma(-1)}}</span>
            <span v-if="item.maxQty > 0">
              <fa icon="chevron-left" class="text-secondary"></fa>
              <span class="text-danger mx-1">{{item.maxQty | comma(-1)}}</span>
            </span>
            {{item.unit.name}}
          </span>
        </div>

        <div v-if="selected === item.id" class="col-12 my-3">
          <div class="form-row">
            <sgv-input-select
              label="สินค้า"
              class="col-12 col-md-6"
              :options="getInventories(item.inventory)"
              select="id"
              v-model="inventoryId">
              <template slot-scope="option">
                {{option.code}} ({{option.name}})
              </template>
            </sgv-input-select>

            <sgv-input-text
              label="Stock ID"
              placeholder="stockId"
              class="col-6 col-md-3"
              v-model="stockId">
            </sgv-input-text>

            <sgv-input-number
              label="จำนวน"
              :precision="2"
              class="col-6 col-md-3"
              v-model="qty">
            </sgv-input-number>
          </div>
          <button
            type="button"
            class="btn btn-warning"
            @click="createOrder(item.id)">
            ตัดสต๊อก
          </button>
        </div>
      </div>

      <div class="" v-if="selected === item.id">
        <div v-for="child in item.children" :key="child.id" class="card my-2">
          <div class="px-2 py-2">
            <div class="">
              <small>
                <fa
                  v-if="!isApprovedOrder(child.stocks) && $auth.hasRole(`doc:${docType}:order:${tag}:approve`)"
                  icon="check"
                  size="lg"
                  class="text-success pointer"
                  @click="approveOrder(child.id)">
                </fa>
                <fa
                  v-if="isApprovedOrder(child.stocks) && $auth.hasRole(`doc:${docType}:order:${tag}:approve`)"
                  icon="times"
                  size="lg"
                  class="text-danger pointer"
                  @dblclick="rollbackOrder(child.id)">
                </fa>
                <fa
                  v-if="!isApprovedOrder(child.stocks) && $auth.hasRole(`doc:${docType}:order:${tag}:add`)"
                  icon="trash"
                  size="lg"
                  class="text-danger float-right pointer"
                  @dblclick="destroyOrder(child.id)">
                </fa>
              </small>
            </div>

            <div v-for="stock in child.stocks" :key="stock.id">
              <small>
                ID: {{stock.id}}
                <span class="float-right">{{stock.parentId}}</span>
                <br>
                สินค้า:
                <span :class="{'text-success': stock.approvedAt, 'text-warning': !stock.approvedAt}">
                  {{stock.inventory.code}} ({{stock.inventory.name}})
                </span>
                <span
                  class="float-right"
                  :class="{'text-danger': stock.qty <= 0, 'text-success': stock.qty > 0}">
                  {{stock.qty}} {{stock.inventory.unit.name}}
                </span>
                <br>
                <span v-if="child.contact">
                  พนักงานผลิต: <span class="text-info">{{child.contact.name}}</span><br />
                </span>
                สร้าง: {{stock.createdUser.name}}
                <span class="float-right">{{stock.createdAt | datetime}}</span>
                <span v-if="stock.approvedAt">
                  <br>
                  ตรวจสอบ: {{stock.approvedUser.name}}
                  <span class="float-right">{{stock.approvedAt | datetime}}</span>
                </span>
              </small>
            </div>

            <div v-for="ledger in child.ledgers" :key="ledger.id">
              <small>
                บัญชี:
                <span :class="{
                  'text-success': ledger.approvedAt,
                  'text-warning': !ledger.approvedAt
                }">
                  {{ledger.account.code}}: {{ledger.account.name}}
                </span>
                <span v-if="ledger.stockId" class="text-info">
                  <fa icon="link"></fa>
                </span>
                <span
                  class="float-right"
                  :class="{'text-danger': ledger.amount <= 0, 'text-success': ledger.amount > 0}">
                  {{ledger.amount | comma}}
                </span>
              </small>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import {
  CREATE_MATERIAL_ORDER, DESTROY_MATERIAL_ORDER,
  APPROVE_MATERIAL_ORDER, ROLLBACK_MATERIAL_ORDER
} from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    employeeId: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      tag: 'material',
      qty: 0,
      stockId: '',
      inventoryId: null,
      selected: null
    }
  },
  methods: {
    isApprovedOrder (stocks) {
      return stocks.some(st => st.approvedAt)
    },
    getInventories (inventory) {
      return [
        inventory,
        ...inventory.junctionChildren
      ]
    },
    totalQty (orders) {
      return this.$form.round(Math.abs(orders.reduce((t,v) => t += v.qty, 0)))
    },
    toggle (item) {
      if (this.selected === item.id) {
        this.selected = null
        this.qty = 0
        this.stockId = ''
        this.inventoryId = null
      } else {
        this.selected = item.id
        this.inventoryId = item.inventory.id
      }
    },
    createOrder (orderId) {
      this.$apollo.mutate({
        mutation: CREATE_MATERIAL_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId,
          qty: this.qty,
          stockId: +this.stockId,
          inventoryId: this.inventoryId,
          employeeId: this.employeeId,
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.$toasted.global.success('เพิ่มรายการสำเร็จ')
      })
      .catch(this.$toasted.global.error)
    },
    destroyOrder (orderId) {
      this.$apollo.mutate({
        mutation: DESTROY_MATERIAL_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.$toasted.global.success('ลบรายการสำเร็จ')
      })
      .catch(this.$toasted.global.error)
    },
    approveOrder (orderId) {
      this.$apollo.mutate({
        mutation: APPROVE_MATERIAL_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.$toasted.global.success('ตรวจรับรายการสำเร็จ')
      })
      .catch(this.$toasted.global.error)
    },
    rollbackOrder (orderId) {
      this.$apollo.mutate({
        mutation: ROLLBACK_MATERIAL_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.$toasted.global.success('ยกเลิกรายการสำเร็จ')
      })
      .catch(this.$toasted.global.error)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
