<template>
  <div class="progress">
    <div
      class="progress-bar progress-bar-striped"
      role="progressbar"
      :style="approved.style"
      :aria-valuenow="approved.qty"
      :aria-valuemin="approved.minQty"
      :aria-valuemax="approved.maxQty">
    </div>
    <div
      class="progress-bar progress-bar-striped bg-warning"
      role="progressbar"
      :style="total.style"
      :aria-valuenow="total.qty"
      :aria-valuemin="total.minQty"
      :aria-valuemax="total.maxQty">
    </div>
  </div>
</template>

<script>
import round from 'lodash/round'

export default {
  props: {
    totalQty: {
      type: Number,
      required: true
    },
    approvedQty: {
      type: Number,
      required: true
    },
    minQty: {
      type: Number,
      required: true
    },
    maxQty: {
      type: Number,
      required: true
    }
  },
  computed: {
    approved () {
      const x = (this.maxQty - 0) / 100
      return {
        style: {'width': round(this.approvedQty / x) + '%'},
        qty: round(this.approvedQty / x),
        minQty: round(this.minQty / x),
        maxQty: round(this.maxQty / x)
      }
    },
    total () {
      const x = (this.maxQty - 0) / 100
      const delta = this.totalQty - this.approvedQty
      return {
        style: {'width': round(delta / x) + '%'},
        qty: round(delta / x),
        minQty: round(this.minQty / x),
        maxQty: round(this.maxQty / x)
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
